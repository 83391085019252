import React, { useState } from "react"
import styled, { useTheme } from "styled-components"
import { useTranslation } from "next-i18next"
import { theme } from "@/common/Mixin"
import Link from "next/link"
import axios from "axios"
import { CircularProgress } from "@mui/material"

interface NewsLetterProps {
  titleType: "h4" | "buttonL"
}

export default function NewsLetter({ titleType = "h4" }: NewsLetterProps) {
  const { t } = useTranslation("common")
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const handleSubscribe = async () => {
    if (!email) {
      setErrorMessage(t("NewsLetter.emptyEmail"))
      return
    }

    setLoading(true)
    setMessage("")
    setErrorMessage("")
    try {
      await axios.post(`/api/subscribe`, {
        email: email
      })

      setMessage(t("NewsLetter.successMessage"))
    } catch (error) {
      var message = t("NewsLetter.errorMessage")
      if (axios.isAxiosError(error)) {
        message += ` ${error.response?.data?.message}`
      }
      setErrorMessage(message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <NewsletterWrapper>
      {titleType === "h4" ? (
        <h4 style={{ marginBottom: "12px" }}>{t("NewsLetter.title")}</h4>
      ) : (
        <div className="button-l" style={{ marginBottom: "12px" }}>
          {t("NewsLetter.title")}
        </div>
      )}
      <ContactFormWrapper>
        <div className="email_input">
          <input
            type="email"
            placeholder={t("NewsLetter.placeholder")}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <button onClick={handleSubscribe}>{t("NewsLetter.button")}</button>
      </ContactFormWrapper>
      {loading && <CircularProgress style={{ padding: "10px" }} />}
      {message && <p>{message}</p>}
      {errorMessage && (
        <p style={{ color: theme.colors.error }}>{errorMessage}</p>
      )}
      <h5>
        {`${t("NewsLetter.desc")} `}
        <Link style={{ color: "inherit" }} href="/company/legal">
          {t("NewsLetter.link")}
        </Link>
      </h5>
    </NewsletterWrapper>
  )
}

const NewsletterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: left;

  h3 {
    margin-bottom: 24px;
  }
  h5 {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.8);
    @media (${theme.lg}) {
      margin-top: 4px;
    }
  }
  a {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 400;
  }

  > div {
    width: 100%;
  }

  > div > div {
    background: none !important;
    padding: 0 !important;
  }

  button {
    padding: 4px 20px;
    cursor: pointer;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0);

    color: ${theme.color.primary};
    border: 1px solid ${theme.color.primary};

    border-radius: 8px;
    font-size: 16px;
    font-weight: 500 !important;
    padding: 12px 28px;
    letter-spacing: 0.02em;
    min-width: 160px;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.3s ease;
    background-position: 0% 50%;
    @media (${theme.lg}) {
      // border-radius: 12px !important;
      padding: 16px 16px;
      height: 48px;
      min-width:;
      // max-height: 40px;
    }

    span.btn-text {
      font-weight: 900;
    }
    span.btn-icon {
      display: flex;
      > div {
        display: flex !important;
      }
    }

    &:not(:disabled):focus,
    &:not(:disabled):hover {
      outline: none;
      background-color: rgba(255, 255, 255, 0.1);
      color: ${theme.color.primary};
    }
  }

  position: relative;
  border-radius: 16px;
`

const ContactFormWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 600px;
  margin-bottom: 6px;
  @media (max-width: 1220px) {
    width: 100%;
  }
  @media (max-width: 575px) {
    flex-direction: row;
    align-items: center;
    // margin-bottom: 25px;
    button {
      width: auto;
      min-width: auto;
    }
  }

  .email_input {
    flex: 1;
    width: 100%;
    margin-right: 20px;

    @media (max-width: 575px) {
      margin-right: 8px;
      margin-bottom: 0;
    }

    input {
      color: ${theme.color.white};
      width: 100%;
      background: transparent;
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.9);
      padding: 10px 15px;
      border-color: ${theme.color.white};
      &:focus {
        color: ${theme.color.textBlack};
      }
      @media (max-width: 575px) {
        height: 48px;
      }
    }

    label {
      font-size: 17px;
      color: ${theme.color.white};
      font-weight: 500;
      padding-left: 10px;
      top: 5px;
      pointer-events: none;
    }
  }
`
